import React from "react";

const shapes = { RoundedBorder8: "rounded-lg" } as const;
const variants = {
  FillBlueA400: "bg-blue_A400 text-white_A700",
  OutlineBlueA400: "border border-blue_A400 border-solid text-blue_A400",
  OutlineBlueA400_1:
    "border-b-4 border-blue_A400 border-solid text-blue_gray_900",
  OutlineBlack9001e: "bg-white_A700 shadow-bs text-blue_A400",
  OutlineBluegray200:
    "border border-blue_gray_200 border-solid text-blue_gray_900",
  OutlineBlueA400_2:
    "border-b-4 border-blue_A400 border-solid text-blue_gray_900",
  OutlineNone:""
} as const;
const sizes = { sm: "p-[7px]", md: "p-2.5", lg: "p-4" } as const;

export type ButtonProps = Omit<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >,
  "onClick"
> &
  Partial<{
    className: string;
    shape: keyof typeof shapes;
    variant: keyof typeof variants;
    size: keyof typeof sizes;
    leftIcon: React.ReactNode;
    rightIcon: React.ReactNode;
    onClick: () => void;
  }>;

const Button: React.FC<React.PropsWithChildren<ButtonProps>> = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape = "",
  variant = "",
  size = "",
  ...restProps
}) => {
  return (
    <button
      className={`${className} ${(shape && shapes[shape]) || ""} ${
        (size && sizes[size]) || ""
      } ${(variant && variants[variant]) || ""}`}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

export { Button };
