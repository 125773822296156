export const handleSectionNavigation = (id: string) => {
  const element = document.getElementById(id);
  const offset = 45;
  const bodyRect = document.body.getBoundingClientRect().top;
  const elementRect = element?.getBoundingClientRect().top ?? 0;
  const elementPosition = elementRect - bodyRect;
  const offsetPosition = elementPosition - offset;

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  });
};

export const getFormatedDate = (creationDateStr: string | number | Date) => {
  const creationDate = new Date(creationDateStr);

  const day = creationDate.getDate();
  const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
    creationDate
  );
  const year = creationDate.getFullYear();

  const formattedDate = `${day} ${month} ${year}`;
  return formattedDate;
};

export function GetEnquiryStatusV2(statuscode: any) {
  let enquiryStatus = "";

  switch (statuscode) {
    case 179070001:
      enquiryStatus = "Unassigned";
      break;
    case 1:
      enquiryStatus = "Open";
      break;
    case 168140000:
      enquiryStatus = "Task";
      break;
    case 179070000:
      enquiryStatus = "Closed";
      break;
    case 2:
      enquiryStatus = "Inactive";
      break;
    default:
      break;
  }
  return enquiryStatus;
}

export function GetViewInDynamicsUrl(
  viewInDynamicsBaseUrl: any,
  enquireId: any
) {
  return `${viewInDynamicsBaseUrl}?appid=${process.env.REACT_APP_appid}&etn=fob_enquiry&id=${enquireId}&pagetype=entityrecord`;
}

export const GetTranscriptSubject = (chatid: any, threadid: any) => {
  return `Transcript from LiveChat ID:${chatid}-${threadid}`;
};

export function getContactMethod(contactMethodCode: any) {
  var enquiryStatus = "";

  switch (contactMethodCode) {
    case 1:
      enquiryStatus = "Any";
      break;
    case 2:
      enquiryStatus = "Email";
      break;
    case 3:
      enquiryStatus = "Phone";
      break;
    case 4:
      enquiryStatus = "Fax";
      break;
    case 5:
      enquiryStatus = "Mail";
      break;
    case 100000000:
      enquiryStatus = "E-mail";
      break;
    case 279640000:
      enquiryStatus = "Face to Face";
      break;
  }
  return enquiryStatus;
}

export function getContactGender(fob_gender_code: null) {
  if (fob_gender_code === null || fob_gender_code === undefined) return "";

  var contactGender = "";

  switch (fob_gender_code) {
    case 1:
      contactGender = "Male";
      break;
    case 2:
      contactGender = "Female";
      break;
    case 3:
      contactGender = "Transgender";
      break;
    case 4:
      contactGender = "Prefer not to say";
      break;
  }
  return contactGender;
}

export function getContactEthnicity(fob_ethnicity_code: null) {
  if (fob_ethnicity_code === null || fob_ethnicity_code === undefined)
    return "";

  var contactEthnicity = "";

  switch (fob_ethnicity_code) {
    case 1:
      contactEthnicity = "Any other Asian Background";
      break;
    case 2:
      contactEthnicity = "Any other Black/African/Caribbean Background";
      break;
    case 3:
      contactEthnicity = "Chinese";
      break;
    case 4:
      contactEthnicity = "Any other Mixed/ Multiple Ethnic Background";
      break;
    case 5:
      contactEthnicity = "Any other Ethnic Group";
      break;
    case 6:
      contactEthnicity = "White English/Welsh/Scottish/N.Irish/British";
      break;
    case 7:
      contactEthnicity = "White Irish";
      break;
    case 8:
      contactEthnicity = "White European";
      break;
    case 9:
      contactEthnicity = "White Gypsy or Traveller";
      break;
    case 10:
      contactEthnicity = "Any other White Background";
      break;
    case 11:
      contactEthnicity = "Mixed White & Caribbean";
      break;
    case 12:
      contactEthnicity = "Mixed White & Black African";
      break;
    case 13:
      contactEthnicity = "Mixed White & Asian";
      break;
    case 14:
      contactEthnicity = "Indian";
      break;
    case 15:
      contactEthnicity = "Pakistani";
      break;
    case 16:
      contactEthnicity = "Bangladeshi";
      break;
    case 17:
      contactEthnicity = "Black African";
      break;
    case 18:
      contactEthnicity = "Black Caribbean";
      break;
    case 19:
      contactEthnicity = "Arab";
      break;
    case 168140000:
      contactEthnicity = "Prefer not to say";
      break;
  }
  return contactEthnicity;
}

export function getContactPreferredPronoun(fob_preferredpronoun_code: null) {
  if (
    fob_preferredpronoun_code === null ||
    fob_preferredpronoun_code === undefined
  )
    return "";

  var preferredPronoun = "";

  switch (fob_preferredpronoun_code) {
    case 179070000:
      preferredPronoun = "He/Him";
      break;
    case 179070001:
      preferredPronoun = "She/Her";
      break;
    case 179070003:
      preferredPronoun = "They/Them";
      break;
    case 179070004:
      preferredPronoun = "Another Pronoun";
      break;
    case 179070005:
      preferredPronoun = "Prefer not to say";
      break;
    case 179070006:
      preferredPronoun = "He/They";
      break;
    case 179070007:
      preferredPronoun = "She/They";
      break;
    case 179070008:
      preferredPronoun = "Xe/They";
      break;
    case 179070009:
      preferredPronoun = "Xe/Xem";
      break;
    case 179070010:
      preferredPronoun = "Ze/Zie/Hir";
      break;
    case 179070011:
      preferredPronoun = "Ze/Zie/They";
      break;
    case 179070002:
      preferredPronoun = "Them";
      break;
  }
  return preferredPronoun;
}

export function getViewInDynamicsUrl(
  viewInDynamicsBaseUrl: any,
  contactId: any
) {
  return `${viewInDynamicsBaseUrl}?appid=${process.env.REACT_APP_appid}&etn=contact&id=${contactId}&pagetype=entityrecord`;
}

export function formatDate(inputDate: string | number | Date) {
  const dateObj = new Date(inputDate);
  const day = String(dateObj.getDate()).padStart(2, "0");
  const month = String(dateObj.getMonth() + 1).padStart(2, "0");
  const year = dateObj.getFullYear();

  return `${day}-${month}-${year}`;
}

export const removeValueIfNeeded = (data: { [x: string]: any }) => {
  const filteredData = Object.keys(data).reduce((acc, key) => {
    if (data[key]) {
      acc[key] = data[key];
    }
    return acc;
  }, {});

  return filteredData;
};
