import React, { useState, useEffect } from "react";
import { Button, Img, Input, SelectBox, Text } from "components";
import { createDetailsWidget } from "@livechat/agent-app-sdk";
import axios from "axios";
import qs from "qs";
import UpdateContactPage from "./UpdateContact";
import CreateContactPage from "./CreateContact";
import EnquiryPage from "./Enquiry";
import SearchPage from "./Search";
import { format } from "date-fns";

import {
  GetEnquiryStatusV2,
  GetTranscriptSubject,
  GetViewInDynamicsUrl,
  formatDate,
  getContactEthnicity,
  getContactGender,
  getContactMethod,
  getContactPreferredPronoun,
} from "utils";

const baseAddressDynamic = process.env.REACT_APP_baseAddressDynamic;

const Home: React.FC = ({}) => {
  const [activeTab, setActiveTab] = useState(1);
  const [userProfile, setUserProfile] = useState(null);
  const [searchItem, setSearchItem] = useState(null);
  const [allTranscripts, setAllTranscripts] = useState(null);
  const [profileData, setProfileData] = useState(null);
  const [newWidget, setNewWidget] = useState(null);
  const [accessToken, setAccessToken] = useState(null);

  const initializeWidget = async (access_token?: any) => {
    try {
      const widget: any = await createDetailsWidget();
      setNewWidget(widget);
      const customerProfileHandler = (profile: any) => {
        setProfileData(profile);
        const { name, email, lastName, number, comments } = profile;
        setUserProfile({
          emailaddress1: email,
          mobilephone: number,
          firstname: name,
          lastname: lastName,
        });
        let data = JSON.stringify({
          emailaddress1: email,
          mobilephone: number,
        });

        let config = {
          method: "get",
          maxBodyLength: Infinity,
          url: `${baseAddressDynamic}/contacts?$select=contactid,fermsapp_studentid,firstname,lastname,emailaddress1,mobilephone,birthdate,fob_gender,fob_ethnicity,fob_preferredpronoun,fermsapp_nationality,address1_line1,address1_postalcode,preferredcontactmethodcode&$filter=( (emailaddress1 eq \'${
            email ? email : ""
          }\') or (mobilephone eq \'${number ? number : ""}\') )`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${access_token}`,
            "OData-MaxVersion": "4.0",
            "OData-Version": "4.0",
            Accept: "application/json; charset=utf-8",
          },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            if (response?.data?.value?.length > 1) {
              setSearchItem(response?.data?.value);
              setActiveTab(3);
            } else {
              let newObj = { ...response?.data?.value[0] };
              getActiveEnquiresByContactId(
                newObj,
                null,
                null,
                null,
                "",
                access_token
              );
            }

            if (
              response?.data?.value?.length > 0 &&
              response &&
              response?.status === 200
            ) {
              const data = response?.data?.value[0];
              if (response?.data?.value?.length > 1) {
                widget
                  ?.modifySection({
                    title: "Microsoft Dynamics",
                    components: [
                      {
                        type: "label_value",
                        data: {
                          label: "",
                          value:
                            "Multiple contacts found, please select to proceed",
                          style: {
                            color: "red",
                          },
                        },
                      },
                      {
                        type: "button",
                        data: {
                          label: "Select Contact",
                          id: "example-button",
                          openApp: true,
                          primary: true,
                        },
                      },
                    ],
                  })
                  .then((response: any) => {})
                  .catch((error: any) => {
                    console.log(
                      "🚀 ~ file: Routes.tsx:87 ~ .then ~ error:",
                      error
                    );
                  });
              } else {
                widget
                  ?.modifySection({
                    title: "Microsoft Dynamics",
                    components: [
                      {
                        type: "label_value",
                        data: {
                          label: "Student ID:",
                          value: data?.fermsapp_studentid
                            ? data?.fermsapp_studentid
                            : "",
                        },
                      },
                      {
                        type: "label_value",
                        data: {
                          label: "First Name:",
                          value: data?.firstname ? data?.firstname : "",
                        },
                      },
                      {
                        type: "label_value",
                        data: {
                          label: "Last Name:",
                          value: data?.lastname ? data?.lastname : "",
                        },
                      },
                      {
                        type: "label_value",
                        data: {
                          label: "E-mail:",
                          value: data?.emailaddress1 ? data?.emailaddress1 : "",
                        },
                      },
                      {
                        type: "label_value",
                        data: {
                          label: "Mobile Phone:",
                          value: data?.mobilephone ? data?.mobilephone : "",
                        },
                      },
                      {
                        type: "label_value",
                        data: {
                          label: "Street:",
                          value: data?.address1_line1
                            ? data?.address1_line1
                            : "",
                        },
                      },
                      {
                        type: "label_value",
                        data: {
                          label: "Postcode:",
                          value: data?.address1_postalcode
                            ? data?.address1_postalcode
                            : "",
                        },
                      },
                      {
                        type: "label_value",
                        data: {
                          label: "DOB:",
                          value: data?.birthdate
                            ? formatDate(data?.birthdate)
                            : "",
                        },
                      },
                      {
                        type: "label_value",
                        data: {
                          label: "Gender:",
                          value: data?.fob_gender
                            ? getContactGender(data?.fob_gender)
                            : "",
                        },
                      },
                      {
                        type: "label_value",
                        data: {
                          label: "Ethnicity:",
                          value: data?.fob_ethnicity
                            ? getContactEthnicity(data?.fob_ethnicity)
                            : "",
                        },
                      },
                      {
                        type: "label_value",
                        data: {
                          label: "Preferred Pronoun:",
                          value: data?.fob_preferredpronoun
                            ? getContactPreferredPronoun(
                                data?.fob_preferredpronoun
                              )
                            : "",
                        },
                      },
                      {
                        type: "label_value",
                        data: {
                          label: "Nationality:",
                          value: data?.fermsapp_nationality
                            ? data?.fermsapp_nationality
                            : "",
                        },
                      },
                      {
                        type: "label_value",
                        data: {
                          label: "Preferred Contact Method:",
                          value: data?.preferredcontactmethodcode
                            ? getContactMethod(data?.preferredcontactmethodcode)
                            : "",
                        },
                      },
                      {
                        type: "button",
                        data: {
                          label: "Update Contact",
                          id: "example-button",
                          openApp: true,
                          primary: true,
                        },
                      },
                    ],
                  })
                  .then((response: any) => {})
                  .catch((error: any) => {
                    console.log(
                      "🚀 ~ file: Routes.tsx:87 ~ .then ~ error:",
                      error
                    );
                  });
              }
            } else {
              setUserProfile({
                emailaddress1: email,
                mobilephone: number,
                firstname: name,
                lastname: lastName,
              });
              setSearchItem(null);
              widget
                ?.modifySection({
                  title: "Microsoft Dynamics",
                  components: [
                    {
                      type: "label_value",
                      data: {
                        label: "Contact doesn’t exist yet",
                      },
                    },
                    {
                      type: "button",
                      data: {
                        label: "Create Contact",
                        id: "example-button",
                        openApp: true,
                        primary: true,
                      },
                    },
                  ],
                })
                .then((response: any) => {
                  console.log(
                    "🚀 ~ file: Routes.tsx:84 ~ .then ~ response:",
                    response
                  );
                  // the widget should be modified now
                })
                .catch((error: any) => {
                  console.log(
                    "🚀 ~ file: Routes.tsx:87 ~ .then ~ error:",
                    error
                  );
                });
            }
          })
          .catch((error) => {
            setUserProfile({
              emailaddress1: email,
              mobilephone: number,
              firstname: name,
              lastname: lastName,
            });
            setSearchItem(null);
            setActiveTab(1);
            widget?.modifySection({
              title: "Microsoft Dynamics",
              components: [
                {
                  type: "label_value",
                  data: {
                    label: "Contact doesn’t exist yet",
                  },
                },
                {
                  type: "button",
                  data: {
                    label: "Create Contact",
                    id: "example-button",
                    openApp: true,
                    primary: true,
                  },
                },
              ],
            });
          });
      };

      widget.on("customer_profile", customerProfileHandler);

      return () => {
        widget.off("customer_profile", customerProfileHandler);
      };
    } catch (error) {
      console.error("Error initializing widget:", error);
    }
  };

  useEffect(() => {
    getAccessToken();
    // Set up an interval to call fetchBusinessDetails every 55 minutes (55 * 60 * 1000 milliseconds)
    // const intervalId = setInterval(getAccessToken, 55 * 60 * 1000);

    // // Clean up the interval when the component unmounts
    // return () => {
    //   clearInterval(intervalId);
    // };
  }, []);

  const handleSearch = (id: any) => {
    let data = JSON.stringify({
      emailaddress1: searchItem?.emailaddress1,
      mobilephone: searchItem?.mobilephone,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseAddressDynamic}/contacts?$select=contactid,fermsapp_studentid,firstname,lastname,emailaddress1,mobilephone,birthdate,fob_gender,fob_ethnicity,fob_preferredpronoun,fermsapp_nationality,address1_line1,address1_postalcode,preferredcontactmethodcode`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${id}`,
        "OData-MaxVersion": "4.0",
        "OData-Version": "4.0",
        Accept: "application/json; charset=utf-8",
        Cookie:
          "ARRAffinity=d0e937da7c6ddc77b6afc457a1e6e9e2923ea2ca87a98a70dbc5d93b7ae9baeb15134d20c556b0b34b9b6ae43ec3f5dcdad61788de889ffc592af7aca85fc1c508DB77074244068A1563762938; ReqClientId=64d00448-ab2a-4eb3-9eae-caa6e0de084f",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log("🚀 ~ file: Home.tsx:365 ~ .then ~ response:", response);
      })
      .catch((error) => {
        console.log("🚀 ~ file: Home.tsx:369 ~ handleSearch ~ error:", error);
      });
  };

  const getAccessToken = () => {
    console.log("called getAccessToken function");
    return new Promise<void>((resolve, reject) => {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_GET_ACCESS_TOKEN_API,
        headers: {
          Accept:
            "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.7",
          "Accept-Language": "en-GB,en-US;q=0.9,en;q=0.8",
        },
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data?.expires_in));
          localStorage.setItem("accessToken", response?.data?.access_token);
          localStorage.setItem("tokenCreationTime", JSON.stringify(Date.now()));
          localStorage.setItem(
            "tokenExpirationTime",
            response?.data?.expires_in
          );
          setAccessToken(response?.data?.access_token);
          initializeWidget(response?.data?.access_token);
          resolve();
        })
        .catch((error) => {
          console.log(error);
          setAccessToken(null);
          resolve();
        });
    });
  };

  const getActiveEnquiresByContactId = async (
    oldData: any,
    isEnquiry = null,
    chatId = null,
    threadid = null,
    allChatTranscripts = "",
    access_token = accessToken
  ) => {
    if (localStorage.getItem("tokenCreationTime")) {
      const tokenCreationTime = localStorage.getItem("tokenCreationTime");
      if (tokenCreationTime) {
        await callAfter55Minutes(Number(tokenCreationTime));
      }
    }
    const token = localStorage.getItem("accessToken");

    let data = "";
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseAddressDynamic}/fob_enquiries?$filter=_fob_enquirer_value eq ${oldData?.contactid} and statecode eq 0`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
        "OData-MaxVersion": "4.0",
        "OData-Version": "4.0",
        Accept: "application/json; charset=utf-8",
        Cookie:
          "ReqClientId=64d00448-ab2a-4eb3-9eae-caa6e0de084f; orgId=263218dd-8453-433d-8c4c-e3a9aeb0a096",
      },
      data: data,
    };

    if (oldData?.contactid) {
      axios
        .request(config)
        .then((response) => {
          let newDataWithEnquiries = { ...oldData };
          if (newDataWithEnquiries?.birthdate) {
            newDataWithEnquiries.birthdate = new Date(
              newDataWithEnquiries?.birthdate
            );
          }

          let filterEnquiresFields = response?.data?.value
            ?.map((item: any) => {
              return {
                fob_enquiryid: item?.fob_enquiryid,
                fob_description: item?.fob_description,
                createdon: item?.createdon,
                statecode: item?.statecode,
                statuscode: item?.statuscode,
                enquiry_status: GetEnquiryStatusV2(item?.statuscode),
                view_enquire_in_dynamics: GetViewInDynamicsUrl(
                  process.env.viewInDynamicsBaseUrl,
                  item?.fob_enquiryid
                ),
              };
            })
            .sort(
              (a: { createdon: string }, b: { createdon: string }) =>
                new Date(b.createdon).getTime() -
                new Date(a.createdon).getTime()
            );

          newDataWithEnquiries.enquiries =
            response?.data?.value?.length > 0 ? filterEnquiresFields : [];
          setSearchItem(newDataWithEnquiries);
          if (isEnquiry === "enquiry") {
            addTranscriptWhenCreateEnquiry(
              filterEnquiresFields[0]?.fob_enquiryid,
              chatId,
              threadid,
              allChatTranscripts
            );
          }
          if (isEnquiry !== "enquiry") setActiveTab(1);
        })
        .catch((error) => {
          console.log(
            "🚀 ~ file: Home.tsx:434 ~ getActiveEnquiresByContactId ~ error:",
            error
          );
        });
    }
  };

  const addTranscriptWhenCreateEnquiry = async (
    enquiryid: any,
    chatId: any,
    threadid: any,
    allChatTranscripts: any
  ) => {
    if (localStorage.getItem("tokenCreationTime")) {
      const tokenCreationTime = localStorage.getItem("tokenCreationTime");
      if (tokenCreationTime) {
        await callAfter55Minutes(Number(tokenCreationTime));
      }
    }
    const token = localStorage.getItem("accessToken");

    let data = JSON.stringify({
      "objectid_fob_enquiry@odata.bind": `/fob_enquiries(${enquiryid})`,
      subject: GetTranscriptSubject(chatId, threadid),
      notetext: allChatTranscripts?.join("\n"),
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseAddressDynamic}/annotations`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
        "OData-MaxVersion": "4.0",
        "OData-Version": "4.0",
        Accept: "application/json; charset=utf-8",
        Cookie:
          "ReqClientId=64d00448-ab2a-4eb3-9eae-caa6e0de084f; orgId=263218dd-8453-433d-8c4c-e3a9aeb0a096",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log("🚀 ~ file: Home.tsx:497 ~ .then ~ response:", response);
      })
      .catch((error) => {
        console.log("🚀 ~ file: Home.tsx:501 ~ error:", error);
      });
  };

  async function callAfter55Minutes(tokenCreationTime: number): Promise<void> {
    return new Promise<void>(async (resolve) => {
      let tokenExpirationTime: any = 3599;
      if (localStorage.getItem("tokenExpirationTime")) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        tokenExpirationTime = localStorage.getItem("tokenExpirationTime");
      }
      const currentTime = Date.now();
      const expirationTokenTime = Math.floor((tokenExpirationTime % 3600) / 60);
      const timeDifference = expirationTokenTime * 60 * 1000; // 55 minutes in milliseconds

      // Calculate the time difference
      const timeElapsed = currentTime - Number(tokenCreationTime);

      // Check if the time difference is greater than 55 minutes
      if (timeElapsed >= timeDifference) {
        await getAccessToken();
      }

      resolve();
    });
  }

  return (
    <>
      <div className="bg-white_A700 flex flex-col font-sourcesanspro items-center justify-start mx-auto w-full">
        <div className="flex flex-col h-[1024px] md:h-auto items-start justify-start max-w-[1440px] w-full">
          <div className="border-b border-blue_gray_200 border-solid flex sm:flex-col flex-row sm:gap-5 items-start justify-start sm:pr-5 pr-[30px] w-full top-nav">
            <Button
              className={
                activeTab === 1
                  ? "cursor-pointer font-semibold  leading-[normal] min-w-[141px] text-blue_gray_900 text-center text-lg remove_focus"
                  : "cursor-pointe  leading-[normal] min-w-[141px] text-blue_gray_900 text-center text-lg remove_focus"
              }
              size="lg"
              onClick={() => setActiveTab(1)}
              variant={activeTab === 1 ? "OutlineBlueA400_2" : "OutlineNone"}
            >
              Contact
            </Button>
            <Button
              className={
                activeTab === 2
                  ? "common-pointer font-semibold  flex flex-row gap-1 items-center justify-center bg-transparent cursor-pointer font-normal leading-[normal] min-w-[139px] text-blue_gray_900 text-center text-lg remove_focus"
                  : "common-pointer bg-transparent flex flex-row gap-1 items-center justify-center cursor-pointer font-normal leading-[normal] min-w-[139px] text-blue_gray_900 text-center text-lg remove_focus"
              }
              onClick={() => setActiveTab(2)}
              size="lg"
              variant={activeTab === 2 ? "OutlineBlueA400_2" : "OutlineNone"}
            >
              <div>Enquiries</div>
              <div>
                {`(${
                  searchItem?.enquiries?.length > 0
                    ? searchItem?.enquiries?.length
                    : 0
                })`}
              </div>
            </Button>
            <Button
              className={
                activeTab === 3
                  ? "bg-transparent font-semibold cursor-pointer  font-normal leading-[normal] min-w-[132px] text-blue_gray_900 text-center text-lg remove_focus"
                  : "bg-transparent cursor-pointer font-normal  leading-[normal] min-w-[132px] text-blue_gray_900 text-center text-lg remove_focus"
              }
              size="lg"
              onClick={() => setActiveTab(3)}
              variant={activeTab === 3 ? "OutlineBlueA400_2" : "OutlineNone"}
            >
              Search
            </Button>
          </div>

          {activeTab === 1 && searchItem ? (
            <UpdateContactPage
              data={searchItem}
              setSearchItem={setSearchItem}
              newWidget={newWidget}
              accessToken={accessToken}
              getActiveEnquiresByContactId={getActiveEnquiresByContactId}
              getAccessToken={getAccessToken}
              callAfter55Minutes={callAfter55Minutes}
            />
          ) : (
            activeTab === 1 && (
              <CreateContactPage
                data={searchItem}
                initializeWidget={initializeWidget}
                setSearchItem={setSearchItem}
                userProfile={userProfile}
                newWidget={newWidget}
                accessToken={accessToken}
                getAccessToken={getAccessToken}
                callAfter55Minutes={callAfter55Minutes}
              />
            )
          )}

          {activeTab === 2 && (
            <EnquiryPage
              data={searchItem}
              allTranscripts={allTranscripts}
              handleSearch={handleSearch}
              profileData={profileData}
              getActiveEnquiresByContactId={getActiveEnquiresByContactId}
              accessToken={accessToken}
              setSearchItem={setSearchItem}
              getAccessToken={getAccessToken}
              callAfter55Minutes={callAfter55Minutes}
            />
          )}
          {activeTab === 3 && (
            <SearchPage
              data={searchItem}
              setSearchItem={setSearchItem}
              setActiveTab={setActiveTab}
              userProfile={userProfile}
              setUserProfile={setUserProfile}
              newWidget={newWidget}
              accessToken={accessToken}
              getActiveEnquiresByContactId={getActiveEnquiresByContactId}
              getAccessToken={getAccessToken}
              callAfter55Minutes={callAfter55Minutes}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Home;
