import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import { Button, Img, Input, List, SelectBox, Text } from "components";

import axios from "axios";
import {
  GetEnquiryStatusV2,
  GetTranscriptSubject,
  GetViewInDynamicsUrl,
  getFormatedDate,
} from "utils";
interface EnquiryPageProps {
  data?: any;
  allTranscripts?: any;
  handleSearch?: (val: any) => void;
  profileData: any;
  getActiveEnquiresByContactId: any;
  accessToken: any;
  setSearchItem: (val: any) => void;
  getAccessToken?: () => Promise<void>;
  callAfter55Minutes:(val:any) => Promise<void>,

}

const getCurrentDate = () => {
  const date = new Date();

  const year = date.getFullYear();
  const month = date.getMonth() + 1; // Months are zero-based
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  // Add leading zeros if necessary
  const formattedMonth = month < 10 ? "0" + month : month;
  const formattedDay = day < 10 ? "0" + day : day;
  const formattedHours = hours < 10 ? "0" + hours : hours;
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
  const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;

  return (
    year +
    "-" +
    formattedMonth +
    "-" +
    formattedDay +
    " " +
    formattedHours +
    ":" +
    formattedMinutes +
    ":" +
    formattedSeconds
  );
};

const statusOptions: any = [
  { label: "Unassigned", value: 179070001 },
  { label: "Open", value: 1 },
  { label: "Task", value: 168140000 },
  { label: "Closed", value: 179070000 },
];
const baseAddressDynamic = process.env.REACT_APP_baseAddressDynamic;

const EnquiryPage: React.FC<EnquiryPageProps> = ({
  data = {},
  allTranscripts = [],
  handleSearch = () => {},
  profileData = {},
  getActiveEnquiresByContactId = () => {},
  accessToken = null,
  setSearchItem = () => {},
  getAccessToken = () => Promise<void>,
  callAfter55Minutes = () => Promise<void>,

}) => {
  const [enquiryLoading, setEnquiryLoading] = useState(false);
  const [enquiryTranscriptLoading, setEnquiryTranscriptLoading] =
    useState(null);
  const [message, setMessage] = useState<any>("");
  const [state, setState] = useState({
    contactid: data?.contactid,
    description: "",
    transcript: {
      subject: GetTranscriptSubject(
        profileData?.chat?.chat_id,
        profileData?.chat?.thread_id
      ),
      notetext: allTranscripts,
    },
    statuscode: 179070000,
  });

  const [refresh, setRefresh] = useState(false);
  const navigate = useNavigate();

  let callCreateEnquiryOnce = 1;
  const createEnquery = async (allChatTranscripts: any) => {
    if (state?.description) {
      if (localStorage.getItem("tokenCreationTime")) {
        const tokenCreationTime = localStorage.getItem("tokenCreationTime");
        if (tokenCreationTime) {
          await callAfter55Minutes(Number(tokenCreationTime));
        }
      }
      const token = localStorage.getItem("accessToken");

      let requestBody = JSON.stringify({
        "fob_Enquirer@odata.bind": `/contacts(${state?.contactid})`,
        fob_source: "179070004",
        statuscode: state?.statuscode,
        fob_description: state?.description,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${baseAddressDynamic}/fob_enquiries`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token}`,
          "OData-MaxVersion": "4.0",
          "OData-Version": "4.0",
          Accept: "application/json; charset=utf-8",
          Cookie:
            "ReqClientId=64d00448-ab2a-4eb3-9eae-caa6e0de084f; orgId=263218dd-8453-433d-8c4c-e3a9aeb0a096",
        },
        data: requestBody,
      };

      axios
        .request(config)
        .then((response) => {
          getActiveEnquiresByContactId(
            data,
            "enquiry",
            profileData?.chat?.chat_id,
            profileData?.chat?.thread_id,
            allChatTranscripts
          );
          setMessage({
            message: "Enquiry created successfully",
            color: "text_success",
          });
          setEnquiryLoading(false);
        })
        .catch(async (error) => {
          if (error?.response?.status === 401 && callCreateEnquiryOnce <= 1) {
            callCreateEnquiryOnce++;
            await getAccessToken();
            createEnquery(allChatTranscripts);
          } else {
            callCreateEnquiryOnce = 1;
            setEnquiryLoading(false);
            setMessage({
              message: "Something went wrong",
              color: "text_warning",
            });
          }
        });
    } else {
      setMessage({
        message: "Please add Enquiry title",
        color: "text_warning",
      });
      setEnquiryLoading(false);
    }
  };

  const getChatTranscript = (str = "", item = null, index = null) => {
    if (str === "enquiry") {
      setEnquiryLoading(true);
    }
    if (str === "addtranscript") {
      setEnquiryTranscriptLoading({
        loading: true,
        index: index,
      });
    }

    let request = {
      chat_id: profileData?.chat?.chat_id,
      thread_id: profileData?.chat?.thread_id,
    };
    let data = JSON.stringify(request);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_ChatUrl,
      headers: {
        Authorization: `Basic ${process.env.REACT_APP_ChatAccessToken}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response: { data: any }) => {
        console.log("🚀 ~ file: index.tsx:191 ~ .then ~ response:", response);
        let allChatTranscripts = [];
        const { data } = response;
        const currentDate = new Date();
        let filterChatData: any = data?.thread?.events?.filter(
          (item: any) => item?.type === "message"
        );

        filterChatData?.map((item: any) => {
          let userFilter: any = data?.users?.filter((user: any) => {
            return item?.author_id === user?.id;
          });

          const userName = userFilter[0]?.name ? userFilter[0]?.name : "";

          const date = new Date(item?.created_at);
          const options: any = {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          };

          const formattedDate = `[${
            date.toLocaleString("en-US", options).split(", ")[1]
          }]`;

          let chatText = item?.text;
          let chatStr = `${userName}${" "}${formattedDate}${" "}${chatText}`;

          allChatTranscripts.push(chatStr);
        });

        if (str === "enquiry") {
          createEnquery(allChatTranscripts);
        }
        if (str === "addtranscript") {
          getTranscriptBySubject(
            item?.fob_enquiryid,
            request?.chat_id,
            request?.thread_id,
            allChatTranscripts
          );
        }
        setEnquiryLoading(false);
        // setEnquiryTranscriptLoading(null);
      })
      .catch((error: any) => {
        console.log(
          "🚀 ~ file: index.tsx:241 ~ getChatTranscript ~ error:",
          error
        );
        setEnquiryLoading(false);
        setEnquiryTranscriptLoading(null);
      });
  };

  let calladdTranscriptToEnquiryOnce = 1;
  const addTranscriptToEnquiry = async (
    enquiryid: any,
    chatId: any,
    threadid: any,
    allChatTranscripts: any
  ) => {
    if (localStorage.getItem("tokenCreationTime")) {
      const tokenCreationTime = localStorage.getItem("tokenCreationTime");
      if (tokenCreationTime) {
        await callAfter55Minutes(Number(tokenCreationTime));
      }
    }
    const token = localStorage.getItem("accessToken");

    let data = JSON.stringify({
      "objectid_fob_enquiry@odata.bind": `/fob_enquiries(${enquiryid})`,
      subject: GetTranscriptSubject(chatId, threadid),
      notetext: allChatTranscripts?.join("\n"),
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseAddressDynamic}/annotations`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
        "OData-MaxVersion": "4.0",
        "OData-Version": "4.0",
        Accept: "application/json; charset=utf-8",
        Cookie:
          "ReqClientId=64d00448-ab2a-4eb3-9eae-caa6e0de084f; orgId=263218dd-8453-433d-8c4c-e3a9aeb0a096",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log("🚀 ~ file: Home.tsx:497 ~ .then ~ response:", response);
        setMessage({
          message: "Enquiry Transcript added successfully",
          color: "text_success",
        });
        setEnquiryTranscriptLoading(null);
      })
      .catch(async (error) => {
        console.log("🚀 ~ file: Home.tsx:501 ~ error:", error);

        if (
          error?.response?.status === 401 &&
          calladdTranscriptToEnquiryOnce <= 1
        ) {
          calladdTranscriptToEnquiryOnce++;
          await getAccessToken();
          addTranscriptToEnquiry(
            enquiryid,
            chatId,
            threadid,
            allChatTranscripts
          );
        } else {
          calladdTranscriptToEnquiryOnce = 1;
          setMessage({
            message: "Something went wrong",
            color: "text_warning",
          });
          setEnquiryTranscriptLoading(null);
        }
      });
  };

  let callGetTranscriptBySubjectOnce = 1;
  const getTranscriptBySubject = async (
    enquiryId: any,
    chatid: any,
    threadid: any,
    allChatTranscripts: any
  ) => {
    if (localStorage.getItem("tokenCreationTime")) {
      const tokenCreationTime = localStorage.getItem("tokenCreationTime");
      if (tokenCreationTime) {
        await callAfter55Minutes(Number(tokenCreationTime));
      }
    }
    const token = localStorage.getItem("accessToken");
    let data = "";

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseAddressDynamic}/annotations?$filter=_objectid_value eq '${enquiryId}' and subject eq '${GetTranscriptSubject(
        chatid,
        threadid
      )}'`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
        "OData-MaxVersion": "4.0",
        "OData-Version": "4.0",
        Accept: "application/json; charset=utf-8",
        Cookie:
          "ReqClientId=64d00448-ab2a-4eb3-9eae-caa6e0de084f; orgId=263218dd-8453-433d-8c4c-e3a9aeb0a096",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log("🚀 ~ file: index.tsx:386 ~ .then ~ response:", response);
        if (response?.data?.value?.length > 0) {
          let filterTranscriptData = response?.data?.value?.sort(
            (a: { createdon: string }, b: { createdon: string }) =>
              new Date(b.createdon).getTime() - new Date(a.createdon).getTime()
          );
          updateLastTranscriptForEnquiry(
            filterTranscriptData[0]?.annotationid,
            allChatTranscripts
          );
        } else {
          addTranscriptToEnquiry(
            enquiryId,
            chatid,
            threadid,
            allChatTranscripts
          );
        }
      })
      .catch(async (error) => {
        console.log(
          "🚀 ~ file: index.tsx:390 ~ error:",
          error?.response?.status
        );

        if (
          error?.response?.status === 401 &&
          callGetTranscriptBySubjectOnce <= 1
        ) {
          callGetTranscriptBySubjectOnce++;
          await getAccessToken();
          getTranscriptBySubject(
            enquiryId,
            chatid,
            threadid,
            allChatTranscripts
          );
        } else {
          callGetTranscriptBySubjectOnce = 1;

          if (error?.response?.status == "404") {
            addTranscriptToEnquiry(
              enquiryId,
              chatid,
              threadid,
              allChatTranscripts
            );
          } else {
            setEnquiryTranscriptLoading(null);
          }
        }
      });
  };

  let callUpdateLastTranscriptForEnquiryOnce = 1;
  const updateLastTranscriptForEnquiry = async (
    transcriptid: any,
    allChatTranscripts: any
  ) => {
    if (localStorage.getItem("tokenCreationTime")) {
      const tokenCreationTime = localStorage.getItem("tokenCreationTime");
      if (tokenCreationTime) {
        await callAfter55Minutes(Number(tokenCreationTime));
      }
    }
    const token = localStorage.getItem("accessToken");
    let data = JSON.stringify({
      notetext: allChatTranscripts?.join("\n"),
    });

    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${baseAddressDynamic}/annotations(${transcriptid})`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
        "OData-MaxVersion": "4.0",
        "OData-Version": "4.0",
        Accept: "application/json; charset=utf-8",
        Cookie:
          "ReqClientId=64d00448-ab2a-4eb3-9eae-caa6e0de084f; orgId=263218dd-8453-433d-8c4c-e3a9aeb0a096",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log("🚀 ~ file: index.tsx:366 ~ .then ~ response:", response);

        setMessage({
          message: "Enquiry Transcript added successfully",
          color: "text_success",
        });
        setEnquiryTranscriptLoading(null);
      })
      .catch(async (error) => {
        console.log("🚀 ~ file: index.tsx:370 ~ error:", error);
        if (
          error?.response?.status === 401 &&
          callUpdateLastTranscriptForEnquiryOnce <= 1
        ) {
          callUpdateLastTranscriptForEnquiryOnce++;
          await getAccessToken();
          updateLastTranscriptForEnquiry(transcriptid, allChatTranscripts);
        } else {
          callUpdateLastTranscriptForEnquiryOnce = 1;
          setMessage({
            message: "Something went wrong",
            color: "text_warning",
          });
          setEnquiryTranscriptLoading(null);
        }
      });
  };

  let callGetActiveEnquiresByIdOnce = 1;
  const getActiveEnquiresById = async (oldData: any) => {
    setRefresh(true);
    if (localStorage.getItem("tokenCreationTime")) {
      const tokenCreationTime = localStorage.getItem("tokenCreationTime");
      if (tokenCreationTime) {
        await callAfter55Minutes(Number(tokenCreationTime));
      }
    }
    const token = localStorage.getItem("accessToken");

    let data = "";

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseAddressDynamic}/fob_enquiries?$filter=_fob_enquirer_value eq ${oldData?.contactid} and statecode eq 0`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
        "OData-MaxVersion": "4.0",
        "OData-Version": "4.0",
        Accept: "application/json; charset=utf-8",
        Cookie:
          "ReqClientId=64d00448-ab2a-4eb3-9eae-caa6e0de084f; orgId=263218dd-8453-433d-8c4c-e3a9aeb0a096",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response: { data: any }) => {
        let newDataWithEnquiries = { ...oldData };
        if (newDataWithEnquiries?.birthdate) {
          newDataWithEnquiries.birthdate = new Date(
            newDataWithEnquiries?.birthdate
          );
        }

        let filterEnquiresFields = response?.data?.value
          ?.map((item: any) => {
            return {
              fob_enquiryid: item?.fob_enquiryid,
              fob_description: item?.fob_description,
              createdon: item?.createdon,
              statecode: item?.statecode,
              statuscode: item?.statuscode,
              enquiry_status: GetEnquiryStatusV2(item?.statuscode),
              view_enquire_in_dynamics: GetViewInDynamicsUrl(
                process.env.viewInDynamicsBaseUrl,
                item?.fob_enquiryid
              ),
            };
          })
          .sort(
            (a: { createdon: string }, b: { createdon: string }) =>
              new Date(b.createdon).getTime() - new Date(a.createdon).getTime()
          );

        newDataWithEnquiries.enquiries =
          response?.data?.value?.length > 0 ? filterEnquiresFields : [];
        setSearchItem(newDataWithEnquiries);
        setRefresh(false);
      })
      .catch(async (error: any) => {
        if (
          error?.response?.status === 401 &&
          callGetActiveEnquiresByIdOnce <= 1
        ) {
          callGetActiveEnquiresByIdOnce++;
          await getAccessToken();
          getActiveEnquiresById(oldData);
        } else {
          callGetActiveEnquiresByIdOnce = 1;
          setRefresh(false);
          console.log(error);
        }
      });
  };

  return (
    <>
      <div
        className={
          enquiryLoading || enquiryTranscriptLoading?.loading
            ? "disabled bg-white_A700 flex flex-col font-sourcesanspro items-center justify-start mx-auto pb-[196px] w-full"
            : "bg-white_A700 flex flex-col font-sourcesanspro items-center justify-start mx-auto pb-[196px] w-full"
        }
      >
        <div className="flex flex-col gap-0.5 items-start justify-start max-w-[1440px] w-full custom-container">
          <div className="flex flex-col items-start justify-start w-full .form-wrap">
            <div className="flex flex-col gap-0.5 items-start justify-start w-full form-wrap">
              <div className="flex flex-col items-start justify-start max-w-[1410px] mx-auto w-full">
                <Text
                  className="border-b border-blue_gray_200 border-solid pb-4 pt-[25px] text-blue_gray_900 w-full section-title"
                  as="h3"
                  variant="h3"
                >
                  Create Enquiry with chat transcript
                </Text>
              </div>
              <div className="border-b border-blue_gray_200 border-solid flex flex-col gap-5 items-start justify-start max-w-[1410px] mx-auto pb-[35px] pt-[10px] w-full form-wrap">
                <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                  <Text
                    className="font-normal text-blue_gray_900 w-full"
                    as="h5"
                    variant="h5"
                  >
                    Title <span className="text-danger">*</span>
                  </Text>
                  <Input
                    wrapClassName="w-full"
                    className="font-normal leading-[normal] p-0 placeholder:text-blue_gray_900 text-blue_gray_900 text-left text-sm w-full"
                    name="inputfield"
                    shape="RoundedBorder6"
                    size="sm"
                    variant="OutlineBluegray200"
                    value={state?.description}
                    onChange={(e: any) =>
                      setState({ ...state, description: e })
                    }
                  ></Input>
                </div>

                <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                  <Text
                    className="font-normal text-blue_gray_900 w-full"
                    as="h5"
                    variant="h5"
                  >
                    Status
                  </Text>
                  <SelectBox
                    className="font-normal leading-[normal] text-blue_gray_900 text-left text-sm w-full"
                    placeholderClassName="text-blue_gray_900"
                    indicator={
                      <Img
                        src="images/img_close_blue_a400.svg"
                        className="h-2 w-2"
                        alt="close"
                      />
                    }
                    size="sm"
                    isSearchable={false}
                    placeholder=""
                    shape="RoundedBorder6"
                    isMulti={false}
                    options={statusOptions}
                    name="inputfield_Five"
                    variant="OutlineBluegray200"
                    value={
                      statusOptions?.filter(
                        (item: any) => item.value === state?.statuscode
                      )[0]
                    }
                    onChange={(e: any) =>
                      setState({
                        ...state,
                        statuscode: e,
                      })
                    }
                  />
                </div>

                <Button
                  className="cursor-pointer font-semibold leading-[normal] text-base text-center text-white_A700 remove_focus"
                  shape="RoundedBorder8"
                  size="md"
                  variant="FillBlueA400"
                  onClick={() => {
                    if (state?.description) {
                      getChatTranscript("enquiry");
                    } else {
                      setMessage({
                        message: "Please add Enquiry title",
                        color: "text_warning",
                      });
                    }
                  }}
                >
                  {enquiryLoading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="loading-spinner" />
                      <span
                        className="button-text"
                        style={{
                          marginLeft: "2px",
                        }}
                      >
                        Creating...
                      </span>
                    </div>
                  ) : (
                    " Create Enquiry"
                  )}
                </Button>
                <div className={message?.color}>{message?.message}</div>
              </div>
            </div>
            <div className="flex flex-col gap-0.5 items-start justify-start w-full">
              <div className="flex flex-col h-14 md:h-auto items-start justify-between max-w-[1390px] mx-auto w-full mb-[25px]">
                <div className="flex w-full edit-contact-wrap border-b border-blue_gray_200_7f border-solid pb-3 pt-[21px] px-3">
                  <Text className="font-semibold leading-[normal] p-0 placeholder:text-blue_gray_900 text-base text-blue_gray_900 text-left w-full bg-transparent border-0">
                    Enquiries
                  </Text>
                  <div
                    className="mt-[3px] mb-0.5 w-3.5"
                    onClick={() => {
                      getActiveEnquiresById(data);
                    }}
                  >
                    {refresh ? (
                      <div className="loading-spinner" />
                    ) : (
                      <Img
                        src="images/img_rotateright1.svg"
                        className="h-[14px] w-[14px]"
                        alt="rotaterightOne"
                      />
                    )}
                  </div>
                </div>
              </div>
              <List
                className="flex-col gap-[15px] grid items-start max-w-[1390px] mx-auto pb-5 md:px-5 w-full enquery_list"
                orientation="vertical"
              >
                {data?.enquiries?.length > 0 ? (
                  data?.enquiries?.map((item: any, index: number) => {
                    return (
                      <div className="flex flex-1 flex-col gap-2 items-start justify-start w-full enquiry-list-item">
                        <div className="flex flex-col items-start justify-start w-full box">
                          <div className="flex flex-col items-center justify-start w-full">
                            <div className="flex flex-row md:gap-10 items-start justify-between w-full">
                              <Text
                                className="w-auto font-normal text-[14px]"
                                as="h6"
                                variant="h6"
                              >
                                {item?.enquiry_status
                                  ? item.enquiry_status
                                  : ""}
                              </Text>
                              <Text
                                className="font-normal text-blue_gray_900 w-auto text-[14px]"
                                as="h5"
                                variant="h5"
                              >
                                {item?.createdon
                                  ? `Created on ${getFormatedDate(
                                      item.createdon
                                    )}`
                                  : ""}
                              </Text>
                            </div>
                            <div className="flex flex-col items-start justify-start mt-0.5 w-full">
                              <Text
                                className="text-blue_gray_400  w-auto text-[16px]"
                                as="h6"
                                variant="h6"
                              >
                                {item?.fob_description
                                  ? item?.fob_description
                                  : ""}
                              </Text>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-row gap-2.5 items-center justify-between w-full">
                          <div
                            className="flex flex-row gap-2 items-center justify-start w-auto cursor-pointer"
                            onClick={() =>
                              window?.open(
                                GetViewInDynamicsUrl(
                                  process.env.REACT_APP_viewInDynamicsBaseUrl,
                                  item?.fob_enquiryid
                                )
                              )
                            }
                          >
                            <Text
                              className="font-normal text-blue_A400 w-auto View_in_MS_Dynamics text-15"
                              as="h4"
                              variant="h4"
                            >
                              View in MS Dynamics
                            </Text>
                            <Img
                              src="images/img_share1.svg"
                              className="h-3 w-3"
                              alt="shareOne"
                            />
                          </div>

                          <Button
                            className="common-pointer cursor-pointer font-semibold leading-[normal] text-blue_A400 text-center text-sm add_transcript add-transcript-btn remove_focus"
                            onClick={() =>
                              getChatTranscript("addtranscript", item, index)
                            }
                            shape="RoundedBorder8"
                            size="sm"
                            variant="OutlineBlueA400"
                          >
                            {enquiryTranscriptLoading?.index === index &&
                            enquiryTranscriptLoading?.loading ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div className="loading-spinner" />
                                <span
                                  className="button-text"
                                  style={{
                                    marginLeft: "2px",
                                  }}
                                >
                                  Adding...
                                </span>
                              </div>
                            ) : (
                              "Add Transcript"
                            )}
                          </Button>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div>{"No enquiries found"}</div>
                )}
              </List>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnquiryPage;
