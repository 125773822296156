import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Input, Text } from "components";
import { ListGroup } from "bootstrap-4-react";
import { createDetailsWidget } from "@livechat/agent-app-sdk";
import axios from "axios";
import { format } from "date-fns";
import {
  formatDate,
  getContactEthnicity,
  getContactGender,
  getContactMethod,
  getContactPreferredPronoun,
} from "utils";

interface SearchPageProps {
  data?: any;
  setSearchItem: (val: any) => void;
  setActiveTab?: (val: any) => void;
  userProfile: any;
  setUserProfile: (val: any) => void | any;
  newWidget?: any;
  accessToken: string | any;
  getActiveEnquiresByContactId: any;
  getAccessToken?: () => Promise<void>;
  callAfter55Minutes?: (val: any) => Promise<void>;
}

const baseAddressDynamic = process.env.REACT_APP_baseAddressDynamic;

const SearchPage: React.FC<SearchPageProps> = ({
  data = {},
  setSearchItem = () => {},
  setActiveTab = () => {},
  userProfile = null,
  setUserProfile = () => {},
  newWidget = null,
  accessToken = null,
  getActiveEnquiresByContactId = () => {},
  getAccessToken = () => Promise<void>,
  callAfter55Minutes = () => Promise<void>,
}) => {
  const searchPayload = data?.length > 1 ? data[0] : data;
  const [state, setState] = useState(
    data
      ? {
          emailaddress1: searchPayload?.emailaddress1,
          mobilephone: searchPayload?.mobilephone,
        }
      : userProfile
  );
  const [loading, setLoading] = useState(false);

  let calledOnlyOnce = 1;
  const searchData = async () => {
    setLoading(true);

    if (localStorage.getItem("tokenCreationTime")) {
      const tokenCreationTime = localStorage.getItem("tokenCreationTime");
      if (tokenCreationTime) {
        await callAfter55Minutes(Number(tokenCreationTime));
      }
    }

    const token = localStorage.getItem("accessToken");

    let data = JSON.stringify({
      emailaddress1: state?.emailaddress1,
      mobilephone: state?.mobilephone,
    });

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseAddressDynamic}/contacts?$select=contactid,fermsapp_studentid,firstname,lastname,emailaddress1,mobilephone,birthdate,fob_gender,fob_ethnicity,fob_preferredpronoun,fermsapp_nationality,address1_line1,address1_postalcode,preferredcontactmethodcode&$filter=( (emailaddress1 eq \'${
        state?.emailaddress1 ? state?.emailaddress1 : ""
      }\') or (mobilephone eq \'${
        state?.mobilephone ? state?.mobilephone : ""
      }\') )`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
        "OData-MaxVersion": "4.0",
        "OData-Version": "4.0",
        Accept: "application/json; charset=utf-8",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if (response?.data?.value?.length > 1) {
          setSearchItem(response?.data?.value);
          setLoading(false);
        } else if (response?.data?.value?.length > 0) {
          let newObj = { ...response?.data?.value[0] };
          if (newObj?.birthdate) {
            newObj.birthdate = new Date(newObj?.birthdate);
          }
          setSearchItem(newObj);
          handleSetItem(newObj);
          setActiveTab(1);
          setLoading(false);
        } else {
          setUserProfile({ ...state });
          setSearchItem(null);
          setLoading(false);
          setActiveTab(1);
        }
      })
      .catch(async (error) => {
        if (error?.response?.status === 401 && calledOnlyOnce <= 1) {
          calledOnlyOnce++;
          await getAccessToken();
          searchData();
        } else {
          calledOnlyOnce = 0;
          setLoading(false);
          console.log(error);
        }
      });
  };

  const handleSetItem = async (item: any) => {
    newWidget
      ?.modifySection({
        title: "Microsoft Dynamics",
        components: [
          {
            type: "label_value",
            data: {
              label: "Student ID:",
              value: item?.fermsapp_studentid ? item?.fermsapp_studentid : "",
            },
          },
          {
            type: "label_value",
            data: {
              label: "First Name:",
              value: item?.firstname ? item?.firstname : "",
            },
          },
          {
            type: "label_value",
            data: {
              label: "Last Name:",
              value: item?.lastname ? item?.lastname : "",
            },
          },
          {
            type: "label_value",
            data: {
              label: "E-mail:",
              value: item?.emailaddress1 ? item?.emailaddress1 : "",
            },
          },
          {
            type: "label_value",
            data: {
              label: "Mobile Phone:",
              value: item?.mobilephone ? item?.mobilephone : "",
            },
          },
          {
            type: "label_value",
            data: {
              label: "Street:",
              value: item?.address1_line1 ? item?.address1_line1 : "",
            },
          },
          {
            type: "label_value",
            data: {
              label: "Postcode:",
              value: item?.address1_postalcode ? item?.address1_postalcode : "",
            },
          },
          {
            type: "label_value",
            data: {
              label: "DOB:",
              value: item?.birthdate ? formatDate(item?.birthdate) : "",
            },
          },
          {
            type: "label_value",
            data: {
              label: "Gender:",
              value: item?.fob_gender ? getContactGender(item?.fob_gender) : "",
            },
          },
          {
            type: "label_value",
            data: {
              label: "Ethnicity:",
              value: item?.fob_ethnicity
                ? getContactEthnicity(item?.fob_ethnicity)
                : "",
            },
          },
          {
            type: "label_value",
            data: {
              label: "Preferred Pronoun:",
              value: item?.fob_preferredpronoun
                ? getContactPreferredPronoun(item?.fob_preferredpronoun)
                : "",
            },
          },
          {
            type: "label_value",
            data: {
              label: "Nationality:",
              value: item?.fermsapp_nationality
                ? item?.fermsapp_nationality
                : "",
            },
          },
          {
            type: "label_value",
            data: {
              label: "Preferred Contact Method:",
              value: item?.preferredcontactmethodcode
                ? getContactMethod(item?.preferredcontactmethodcode)
                : "",
            },
          },
          {
            type: "button",
            data: {
              label: "Update Contact",
              id: "example-button",
              openApp: true,
              primary: true,
            },
          },
        ],
      })
      .then((response: any) => {
        console.log("🚀 ~ file: index.tsx:528 ~ .then ~ response:", response);
      })
      .catch((error: any) => {
        console.log("🚀 ~ file: index.tsx:532 ~ handleSetItem ~ error:", error);
      });

    let newObj = { ...item };
    getActiveEnquiresByContactId(newObj);

    // if (newObj?.birthdate) {
    //   newObj.birthdate = new Date(newObj?.birthdate);
    // }
    // setSearchItem(newObj);
    // setActiveTab(1);
  };

  return (
    <>
      <div
        className={
          loading
            ? "disabled bg-white_A700 flex flex-col font-sourcesanspro items-center justify-start mx-auto pb-[694px] w-full"
            : "bg-white_A700 flex flex-col font-sourcesanspro items-center justify-start mx-auto pb-[694px] w-full"
        }
      >
        <div className="flex flex-col gap-0.5 items-start justify-start max-w-[1440px] w-full custom-container">
          <div className="flex flex-col items-start justify-start w-full form-wrap">
            <div className="flex flex-col items-start justify-start pt-[25px] w-full">
              <Text className="text-blue_gray_900 w-auto" as="h3" variant="h3">
                Search
              </Text>
            </div>
            <div className="flex flex-col items-start justify-start pb-2  w-auto">
              <Text
                className="font-normal text-blue_gray_400 w-auto"
                as="h5"
                variant="h5"
              >
                Enter following fields and begin your search.
              </Text>
            </div>
          </div>
          <div className="flex flex-col gap-5 items-start justify-start  pt-[5px] w-full form-wrap">
            <div className="flex flex-col gap-2.5 items-start justify-start w-full">
              <Text
                className="font-normal text-blue_gray_900 w-full"
                as="h5"
                variant="h5"
              >
                Email
              </Text>
              <Input
                wrapClassName="w-full"
                className="font-normal leading-[normal] p-0 placeholder:text-blue_gray_900 text-blue_gray_900 text-left text-sm w-full"
                type="email"
                name="email"
                shape="RoundedBorder6"
                size="sm"
                variant="OutlineBluegray200"
                value={state?.emailaddress1}
                onChange={(e: any) => setState({ ...state, emailaddress1: e })}
              ></Input>
            </div>
            <div className="flex flex-col gap-2.5 items-start justify-start w-full">
              <Text
                className="font-normal text-blue_gray_900 w-full"
                as="h5"
                variant="h5"
              >
                Phone Number
              </Text>
              <Input
                wrapClassName="w-full"
                className="font-normal leading-[normal] p-0 placeholder:text-blue_gray_900 text-blue_gray_900 text-left text-sm w-full"
                type="email"
                name="email_One"
                shape="RoundedBorder6"
                size="sm"
                variant="OutlineBluegray200"
                value={state?.mobilephone}
                onChange={(e: any) => setState({ ...state, mobilephone: e })}
              ></Input>
            </div>
            <Button
              className="cursor-pointer font-semibold leading-[normal] text-base text-center text-white_A700 mt-[10px] remove_focus"
              shape="RoundedBorder8"
              size="md"
              variant="FillBlueA400"
              onClick={searchData}
              data-target="#exampleModal"
            >
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="loading-spinner" />
                  <span
                    className="button-text"
                    style={{
                      marginLeft: "2px",
                    }}
                  >
                    Search...
                  </span>
                </div>
              ) : (
                "Search"
              )}
            </Button>
            {data?.length > 1 && (
              <div>
                <ListGroup mb="3 search-list">
                  {data?.length > 1 &&
                    data?.map((item: any) => {
                      return (
                        <ListGroup.Link
                          href="#"
                          onClick={() => {
                            handleSetItem(item);
                          }}
                        >
                          <div>
                            {item?.emailaddress1 ? item?.emailaddress1 : ""}
                          </div>
                          <div className="name">
                            {item?.firstname ? item?.firstname : ""}{" "}
                            {item?.lastname ? item?.lastname : ""}
                          </div>
                          <div className="phone">
                            {item?.mobilephone ? item?.mobilephone : ""}
                          </div>
                        </ListGroup.Link>
                      );
                    })}
                </ListGroup>
              </div>
            )}

            <div></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchPage;
