import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { Button, Img, Input, SelectBox, Text } from "components";
import axios from "axios";
import { format } from "date-fns";
import {
  formatDate,
  getContactEthnicity,
  getContactGender,
  getContactMethod,
  getContactPreferredPronoun,
  getViewInDynamicsUrl,
} from "utils";
import Loader from "components/Loader";

const inputfieldFiveOptionsList: any = [
  { label: "Any", value: 1 },
  { label: "Email", value: 2 },
  { label: "Phone", value: 3 },
  { label: "Fax", value: 4 },
  { label: "Mail", value: 5 },
  { label: "E-mail", value: 100000000 },
  { label: "Face to Face", value: 279640000 },
];

const genderOptions: any = [
  { label: "Male", value: 1 },
  { label: "Female", value: 2 },
  { label: "Transgender", value: 3 },
  { label: "Prefer not to say", value: 4 },
];

const ethnicityOptions: any = [
  { label: "Any other Asian Background", value: 1 },
  { label: "Any other Black/African/Caribbean Background", value: 2 },
  { label: "Chinese", value: 3 },
  { label: "Any other Mixed/ Multiple Ethnic Background", value: 4 },
  { label: "Any other Ethnic Group", value: 5 },
  { label: "White English/Welsh/Scottish/N.Irish/British", value: 6 },
  { label: "White Irish", value: 7 },
  { label: "White European", value: 8 },
  { label: "White Gypsy or Traveller", value: 9 },
  { label: "Any other White Background", value: 10 },
  { label: "Mixed White & Caribbean", value: 11 },
  { label: "Mixed White & Black African", value: 12 },
  { label: "Mixed White & Asian", value: 13 },
  { label: "Indian", value: 14 },
  { label: "Pakistani", value: 15 },
  { label: "Bangladeshi", value: 16 },
  { label: "Black African", value: 17 },
  { label: "Black Caribbean", value: 18 },
  { label: "Arab", value: 19 },
  { label: "Prefer not to say", value: 168140000 },
];

const preferredPronounOptions: any = [
  { label: "He/Him", value: 179070000 },
  { label: "She/Her", value: 179070001 },
  { label: "They/Them", value: 179070003 },
  { label: "Another Pronoun", value: 179070004 },
  { label: "Prefer not to say", value: 179070005 },
  { label: "He/They", value: 179070006 },
  { label: "She/They", value: 179070007 },
  { label: "Xe/They", value: 179070008 },
  { label: "Xe/Xem", value: 179070009 },
  { label: "Ze/Zie/Hir", value: 179070010 },
  { label: "Ze/Zie/They", value: 179070011 },
  { label: "Them", value: 179070002 },
];
interface UpdateContactPageProps {
  data?: any;
  handleSearch?: () => void;
  newWidget: any;
  setSearchItem: (val: any) => void;
  accessToken: string | any;
  getActiveEnquiresByContactId: any;
  getAccessToken?: () => Promise<void>;
  callAfter55Minutes: (val: any) => Promise<void>;
}

const baseAddressDynamic = process.env.REACT_APP_baseAddressDynamic;

const UpdateContactPage: React.FC<UpdateContactPageProps> = ({
  data = {},
  handleSearch = () => {},
  setSearchItem = () => {},
  newWidget = null,
  accessToken = null,
  getActiveEnquiresByContactId = () => {},
  getAccessToken = () => Promise<void>,
  callAfter55Minutes = () => Promise<void>,
}) => {
  const [state, setState] = useState(data);
  const [message, setMessage] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getFormatedDate = (dateString: string | number | Date) => {
    const dateObj = new Date(dateString);
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth() + 1;
    const day = dateObj.getDate();
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
    return formattedDate;
  };

  let updateUserOnlyOnce = 1;
  const handleSubmit = async () => {
    setLoading(true);

    if (localStorage.getItem("tokenCreationTime")) {
      const tokenCreationTime = localStorage.getItem("tokenCreationTime");
      if (tokenCreationTime) {
        await callAfter55Minutes(Number(tokenCreationTime));
      }
    }
    const token = localStorage.getItem("accessToken");

    let requestBody = {
      fermsapp_studentid: state?.fermsapp_studentid
        ? state.fermsapp_studentid
        : "",
      firstname: state?.firstname ? state.firstname : "",
      lastname: state?.lastname ? state.lastname : "",
      emailaddress1: state?.emailaddress1 ? state.emailaddress1 : "",
      mobilephone: state?.mobilephone ? state.mobilephone : "",
      address1_line1: state?.address1_line1 ? state.address1_line1 : "",
      address1_postalcode: state?.address1_postalcode
        ? state?.address1_postalcode
        : "",
      birthdate: state?.birthdate ? getFormatedDate(state?.birthdate) : "",
      fob_gender: state?.fob_gender ? state.fob_gender : null,
      fob_ethnicity: state?.fob_ethnicity ? state?.fob_ethnicity : null,
      fob_preferredpronoun: state?.fob_preferredpronoun
        ? state?.fob_preferredpronoun
        : null,
      fermsapp_nationality: state?.fermsapp_nationality
        ? state?.fermsapp_nationality
        : "",
      preferredcontactmethodcode: state?.preferredcontactmethodcode
        ? state?.preferredcontactmethodcode
        : null,
    };

    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${baseAddressDynamic}/contacts(${state.contactid})?$select=contactid,fermsapp_studentid,firstname,lastname,emailaddress1,mobilephone,birthdate,fob_gender,fob_ethnicity,fob_preferredpronoun,fermsapp_nationality,address1_line1,address1_postalcode,preferredcontactmethodcode`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
        "OData-MaxVersion": "4.0",
        "OData-Version": "4.0",
        Accept: "application/json; charset=utf-8",
        Prefer: "return=representation",
      },
      data: requestBody,
    };

    axios
      .request(config)
      .then((response) => {
        setMessage("Data updated successfully.");
        let newObj = { ...response?.data };
        if (newObj?.birthdate) {
          newObj.birthdate = new Date(newObj?.birthdate);
        }
        getActiveEnquiresByContactId(newObj);
        // setSearchItem(newObj);
        let item = { ...newObj };

        try {
          newWidget
            ?.modifySection({
              title: "Microsoft Dynamics",
              components: [
                {
                  type: "label_value",
                  data: {
                    label: "Student ID:",
                    value: item?.fermsapp_studentid
                      ? item?.fermsapp_studentid
                      : "",
                  },
                },
                {
                  type: "label_value",
                  data: {
                    label: "First Name:",
                    value: item?.firstname ? item?.firstname : "",
                  },
                },
                {
                  type: "label_value",
                  data: {
                    label: "Last Name:",
                    value: item?.lastname ? item?.lastname : "",
                  },
                },
                {
                  type: "label_value",
                  data: {
                    label: "E-mail:",
                    value: item?.emailaddress1 ? item?.emailaddress1 : "",
                  },
                },
                {
                  type: "label_value",
                  data: {
                    label: "Mobile Phone:",
                    value: item?.mobilephone ? item?.mobilephone : "",
                  },
                },
                {
                  type: "label_value",
                  data: {
                    label: "Street:",
                    value: item?.address1_line1 ? item?.address1_line1 : "",
                  },
                },
                {
                  type: "label_value",
                  data: {
                    label: "Postcode:",
                    value: item?.address1_postalcode
                      ? item?.address1_postalcode
                      : "",
                  },
                },
                {
                  type: "label_value",
                  data: {
                    label: "DOB:",
                    value: item?.birthdate ? formatDate(item?.birthdate) : "",
                  },
                },
                {
                  type: "label_value",
                  data: {
                    label: "Gender:",
                    value: item?.fob_gender
                      ? getContactGender(item?.fob_gender)
                      : "",
                  },
                },
                {
                  type: "label_value",
                  data: {
                    label: "Ethnicity:",
                    value: item?.fob_ethnicity
                      ? getContactEthnicity(item?.fob_ethnicity)
                      : "",
                  },
                },
                {
                  type: "label_value",
                  data: {
                    label: "Preferred Pronoun:",
                    value: item?.fob_preferredpronoun
                      ? getContactPreferredPronoun(item?.fob_preferredpronoun)
                      : "",
                  },
                },
                {
                  type: "label_value",
                  data: {
                    label: "Nationality:",
                    value: item?.fermsapp_nationality
                      ? item?.fermsapp_nationality
                      : "",
                  },
                },
                {
                  type: "label_value",
                  data: {
                    label: "Preferred Contact Method:",
                    value: item?.preferredcontactmethodcode
                      ? getContactMethod(item?.preferredcontactmethodcode)
                      : "",
                  },
                },
                {
                  type: "button",
                  data: {
                    label: "Update Contact",
                    id: "example-button",
                    openApp: true,
                    primary: true,
                  },
                },
              ],
            })
            .then((response: any) => {
              setLoading(false);
              console.log(
                "🚀 ~ file: index.tsx:177 ~ .then ~ response:",
                response
              );
            })
            .catch((error: any) => {
              setLoading(false);
              console.log(
                "🚀 ~ file: index.tsx:181 ~ customerProfileHandler ~ error:",
                error
              );
            });
        } catch (error) {
          console.error("Error initializing widget:", error);
          setLoading(false);
        }
        // handleSearch();
        setLoading(false);
      })
      .catch(async (error) => {
        if (error?.response?.status === 401 && updateUserOnlyOnce <= 1) {
          updateUserOnlyOnce++;
          await getAccessToken();
          handleSubmit();
        } else {
          updateUserOnlyOnce = 1;
          setMessage("");
          setLoading(false);
        }
      });
  };

  let refreshContactByIdOnlyOnce = 1;
  const refreshContactById = async (id: string) => {
    setRefresh(true);
    if (localStorage.getItem("tokenCreationTime")) {
      const tokenCreationTime = localStorage.getItem("tokenCreationTime");
      if (tokenCreationTime) {
        await callAfter55Minutes(Number(tokenCreationTime));
      }
    }
    const token = localStorage.getItem("accessToken");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseAddressDynamic}/contacts(${id})?$select=contactid,fermsapp_studentid,firstname,lastname,emailaddress1,mobilephone,birthdate,fob_gender,fob_ethnicity,fob_preferredpronoun,fermsapp_nationality,address1_line1,address1_postalcode,preferredcontactmethodcode`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
        "OData-MaxVersion": "4.0",
        "OData-Version": "4.0",
        Accept: "application/json; charset=utf-8",
        Prefer: "return=representation",
        Cookie:
          "ReqClientId=64d00448-ab2a-4eb3-9eae-caa6e0de084f; orgId=263218dd-8453-433d-8c4c-e3a9aeb0a096",
      },
    };

    axios
      .request(config)
      .then((response) => {
        let newObj = { ...response?.data };
        if (newObj?.birthdate) {
          newObj.birthdate = new Date(newObj?.birthdate);
        }
        setState(newObj);
        getActiveEnquiresByContactId(newObj);
        // setSearchItem(newObj);
        let item = { ...newObj };
        setRefresh(false);

        try {
          newWidget
            ?.modifySection({
              title: "Microsoft Dynamics",
              components: [
                {
                  type: "label_value",
                  data: {
                    label: "Student ID:",
                    value: item?.fermsapp_studentid
                      ? item?.fermsapp_studentid
                      : "",
                  },
                },
                {
                  type: "label_value",
                  data: {
                    label: "First Name:",
                    value: item?.firstname ? item?.firstname : "",
                  },
                },
                {
                  type: "label_value",
                  data: {
                    label: "Last Name:",
                    value: item?.lastname ? item?.lastname : "",
                  },
                },
                {
                  type: "label_value",
                  data: {
                    label: "E-mail:",
                    value: item?.emailaddress1 ? item?.emailaddress1 : "",
                  },
                },
                {
                  type: "label_value",
                  data: {
                    label: "Mobile Phone:",
                    value: item?.mobilephone ? item?.mobilephone : "",
                  },
                },
                {
                  type: "label_value",
                  data: {
                    label: "Street:",
                    value: item?.address1_line1 ? item?.address1_line1 : "",
                  },
                },
                {
                  type: "label_value",
                  data: {
                    label: "Postcode:",
                    value: item?.address1_postalcode
                      ? item?.address1_postalcode
                      : "",
                  },
                },
                {
                  type: "label_value",
                  data: {
                    label: "DOB:",
                    value: item?.birthdate ? formatDate(item?.birthdate) : "",
                  },
                },
                {
                  type: "label_value",
                  data: {
                    label: "Gender:",
                    value: item?.fob_gender
                      ? getContactGender(item?.fob_gender)
                      : "",
                  },
                },
                {
                  type: "label_value",
                  data: {
                    label: "Ethnicity:",
                    value: item?.fob_ethnicity
                      ? getContactEthnicity(item?.fob_ethnicity)
                      : "",
                  },
                },
                {
                  type: "label_value",
                  data: {
                    label: "Preferred Pronoun:",
                    value: item?.fob_preferredpronoun
                      ? getContactPreferredPronoun(item?.fob_preferredpronoun)
                      : "",
                  },
                },
                {
                  type: "label_value",
                  data: {
                    label: "Nationality:",
                    value: item?.fermsapp_nationality
                      ? item?.fermsapp_nationality
                      : "",
                  },
                },
                {
                  type: "label_value",
                  data: {
                    label: "Preferred Contact Method:",
                    value: item?.preferredcontactmethodcode
                      ? getContactMethod(item?.preferredcontactmethodcode)
                      : "",
                  },
                },
                {
                  type: "button",
                  data: {
                    label: "Update Contact",
                    id: "example-button",
                    openApp: true,
                    primary: true,
                  },
                },
              ],
            })
            .then((response: any) => {
              setRefresh(false);
              console.log(
                "🚀 ~ file: index.tsx:177 ~ .then ~ response:",
                response
              );
            })
            .catch((error: any) => {
              setRefresh(false);
              console.log(
                "🚀 ~ file: index.tsx:181 ~ customerProfileHandler ~ error:",
                error
              );
            });
        } catch (error) {
          console.error("Error initializing widget:", error);
          setRefresh(false);
        }
        setRefresh(false);
      })
      .catch(async (error) => {
        if (
          error?.response?.status === 401 &&
          refreshContactByIdOnlyOnce <= 1
        ) {
          refreshContactByIdOnlyOnce++;
          await getAccessToken();
          refreshContactById(id);
        } else {
          refreshContactByIdOnlyOnce = 1;
          setRefresh(false);
        }
        console.log(error);
      });
  };

  return (
    <>
      <div
        className={
          loading
            ? "disabled bg-white_A700 flex flex-col font-sourcesanspro items-center justify-start mx-auto w-full"
            : "bg-white_A700 flex flex-col font-sourcesanspro items-center justify-start mx-auto w-full"
        }
      >
        <div className="flex flex-col h-[1024px] md:h-auto items-start justify-start max-w-[1440px] w-full custom-container">
          <div className="text_success mt-2 ml-0">{message}</div>
          <div className="flex flex-col h-full items-start justify-start w-full">
            <div className="flex flex-col gap-4 items-start justify-start  w-full">
              <Input
                wrapClassName="flex w-full edit-contact-wrap"
                className="font-semibold leading-[normal] p-0 placeholder:text-blue_gray_900 text-base text-blue_gray_900 text-left w-full"
                name="formheadingfram"
                placeholder="Edit Contact"
                suffix={
                  <div
                    className="mt-[3px] mb-0.5  w-3.5 "
                    onClick={() => refreshContactById(state?.contactid)}
                  >
                    {refresh ? (
                      <div className="loading-spinner" />
                    ) : (
                      <Img
                        src="images/img_rotateright1.svg"
                        className="my-auto"
                        alt="refresh"
                      />
                    )}
                  </div>
                }
                size="lg"
                variant="OutlineBluegray2007f"
              ></Input>
              <div className="flex flex-col gap-1 items-start justify-start max-w-[1390px] mx-auto pb-3 w-full">
                <div className="flex flex-row gap-1.5 items-start justify-start w-auto">
                  <Text
                    className="font-normal text-blue_gray_400 w-auto"
                    as="h4"
                    variant="h4"
                  >
                    Email:
                  </Text>
                  <Text
                    className="font-semibold text-blue_gray_900 w-auto"
                    as="h4"
                    variant="h4"
                  >
                    {state?.emailaddress1 ? state.emailaddress1 : ""}
                  </Text>
                </div>
                {/* <div className="flex flex-row gap-1.5 items-start justify-start w-auto">
                  <Text
                    className="font-normal text-blue_gray_400 w-auto"
                    as="h4"
                    variant="h4"
                  >
                    Company:
                  </Text>
                  <Text
                    className="font-semibold text-blue_gray_900 w-auto"
                    as="h4"
                    variant="h4"
                  >
                    {state?.company ? state.company : ""}
                  </Text>
                </div> */}

                <div
                  className="flex flex-row gap-2 items-start justify-start w-auto cursor-pointer"
                  onClick={() =>
                    window?.open(
                      getViewInDynamicsUrl(
                        process.env.REACT_APP_viewInDynamicsBaseUrl,
                        state?.contactid
                      )
                    )
                  }
                >
                  <Text
                    className="font-normal text-blue_A400 w-auto"
                    as="h4"
                    variant="h4"
                  >
                    View in MS Dynamics
                  </Text>
                  <Img
                    src="images/img_share1.svg"
                    className="h-3 w-3"
                    alt="shareOne"
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start justify-start w-full">
              <Text
                className="border-b border-blue_gray_200_7f  font-semibold section-title py-[15px] text-blue_gray_900 w-full"
                as="h4"
                variant="h4"
              >
                Contact Information
              </Text>
              <div className="flex flex-col gap-5 items-start justify-start max-w-[1390px] mx-auto pt-[10px] w-full form-wrap pb-5">
                <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                  <Text
                    className="font-normal text-blue_gray_900 w-full"
                    as="h5"
                    variant="h5"
                  >
                    Student ID
                  </Text>
                  <Input
                    wrapClassName="w-full"
                    className="font-normal leading-[normal] p-0 placeholder:text-blue_gray_900 text-blue_gray_900 text-left text-sm w-full"
                    name="inputfield"
                    placeholder=""
                    shape="RoundedBorder6"
                    size="sm"
                    variant="OutlineBluegray200"
                    value={state?.fermsapp_studentid}
                    onChange={(e: any) =>
                      setState({ ...state, fermsapp_studentid: e })
                    }
                  ></Input>
                </div>
                <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                  <Text
                    className="font-normal text-blue_gray_900 w-full"
                    as="h5"
                    variant="h5"
                  >
                    First name
                  </Text>
                  <Input
                    wrapClassName="w-full"
                    className="font-normal leading-[normal] p-0 placeholder:text-blue_gray_900 text-blue_gray_900 text-left text-sm w-full"
                    name="inputfield"
                    placeholder=""
                    shape="RoundedBorder6"
                    size="sm"
                    variant="OutlineBluegray200"
                    value={state?.firstname}
                    onChange={(e: any) => setState({ ...state, firstname: e })}
                  ></Input>
                </div>
                <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                  <Text
                    className="font-normal text-blue_gray_900 w-full"
                    as="h5"
                    variant="h5"
                  >
                    Last name
                  </Text>
                  <Input
                    wrapClassName="w-full"
                    className="font-normal leading-[normal] p-0 placeholder:text-blue_gray_900 text-blue_gray_900 text-left text-sm w-full"
                    name="inputfield_One"
                    placeholder=""
                    shape="RoundedBorder6"
                    size="sm"
                    variant="OutlineBluegray200"
                    value={state?.lastname}
                    onChange={(e: any) => setState({ ...state, lastname: e })}
                  ></Input>
                </div>
                <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                  <Text
                    className="font-normal text-blue_gray_900 w-full"
                    as="h5"
                    variant="h5"
                  >
                    E-mail
                  </Text>
                  <Input
                    wrapClassName="w-full"
                    className="font-normal leading-[normal] p-0 placeholder:text-blue_gray_900 text-blue_gray_900 text-left text-sm w-full"
                    name="inputfield_Two"
                    placeholder=""
                    shape="RoundedBorder6"
                    size="sm"
                    variant="OutlineBluegray200"
                    value={state?.emailaddress1}
                    onChange={(e: any) =>
                      setState({ ...state, emailaddress1: e })
                    }
                  ></Input>
                </div>
                <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                  <Text
                    className="font-normal text-blue_gray_900 w-full"
                    as="h5"
                    variant="h5"
                  >
                    Mobile Phone
                  </Text>
                  <Input
                    wrapClassName="w-full"
                    className="font-normal leading-[normal] p-0 placeholder:text-blue_gray_900 text-blue_gray_900 text-left text-sm w-full"
                    type="number"
                    name="mobileNo"
                    placeholder=""
                    shape="RoundedBorder6"
                    size="sm"
                    variant="OutlineBluegray200"
                    value={state?.mobilephone}
                    onChange={(e: any) =>
                      setState({ ...state, mobilephone: e })
                    }
                  ></Input>
                </div>
                <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                  <Text
                    className="font-normal text-blue_gray_900 w-full"
                    as="h5"
                    variant="h5"
                  >
                    Street
                  </Text>
                  <Input
                    wrapClassName="w-full"
                    className="font-normal leading-[normal] p-0 placeholder:text-blue_gray_900 text-blue_gray_900 text-left text-sm w-full"
                    type="text"
                    name="inputfield_Three"
                    placeholder=""
                    shape="RoundedBorder6"
                    size="sm"
                    variant="OutlineBluegray200"
                    value={state?.address1_line1}
                    onChange={(e: any) =>
                      setState({ ...state, address1_line1: e })
                    }
                  ></Input>
                </div>
                <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                  <Text
                    className="font-normal text-blue_gray_900 w-full"
                    as="h5"
                    variant="h5"
                  >
                    Postcode
                  </Text>
                  <Input
                    wrapClassName="w-full"
                    className="font-normal leading-[normal] p-0 placeholder:text-blue_gray_900 text-blue_gray_900 text-left text-sm w-full"
                    type="text"
                    name="inputfield_Three"
                    placeholder=""
                    shape="RoundedBorder6"
                    size="sm"
                    variant="OutlineBluegray200"
                    value={state?.address1_postalcode}
                    onChange={(e: any) =>
                      setState({ ...state, address1_postalcode: e })
                    }
                  ></Input>
                </div>
                <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                  <Text
                    className="font-normal text-blue_gray_900 w-full"
                    as="h5"
                    variant="h5"
                  >
                    DOB
                  </Text>
                  <DatePicker
                    className="font-normal leading-[normal] p-0 placeholder:text-blue_gray_900 text-blue_gray_900 text-left text-sm w-full"
                    selected={state?.birthdate ? state?.birthdate : ""}
                    dateFormat="dd/MM/yyyy"
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={500}
                    maxDate={new Date()}
                    onChange={(date) => {
                      setState({ ...state, birthdate: date });
                    }}
                  />
                </div>

                <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                  <Text
                    className="font-normal text-blue_gray_900 w-full"
                    as="h5"
                    variant="h5"
                  >
                    Gender
                  </Text>
                  <SelectBox
                    className="font-normal leading-[normal] text-blue_gray_900 text-left text-sm w-full"
                    placeholderClassName="text-blue_gray_900"
                    indicator={
                      <Img
                        src="images/img_close_blue_a400.svg"
                        className="h-2 w-2"
                        alt="close"
                      />
                    }
                    size="sm"
                    isSearchable={false}
                    placeholder=""
                    shape="RoundedBorder6"
                    isMulti={false}
                    options={genderOptions}
                    name="inputfield_Five"
                    variant="OutlineBluegray200"
                    value={
                      genderOptions?.filter(
                        (item: any) => item.value === state?.fob_gender
                      )[0]
                    }
                    onChange={(e: any) =>
                      setState({
                        ...state,
                        fob_gender: e,
                      })
                    }
                  />
                </div>
                <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                  <Text
                    className="font-normal text-blue_gray_900 w-full"
                    as="h5"
                    variant="h5"
                  >
                    Ethnicity
                  </Text>
                  <SelectBox
                    className="font-normal leading-[normal] text-blue_gray_900 text-left text-sm w-full"
                    placeholderClassName="text-blue_gray_900"
                    indicator={
                      <Img
                        src="images/img_close_blue_a400.svg"
                        className="h-2 w-2"
                        alt="close"
                      />
                    }
                    size="sm"
                    isSearchable={false}
                    placeholder=""
                    shape="RoundedBorder6"
                    isMulti={false}
                    options={ethnicityOptions}
                    name="inputfield_Five"
                    variant="OutlineBluegray200"
                    value={
                      state?.fob_ethnicity &&
                      ethnicityOptions[state?.fob_ethnicity - 1]
                    }
                    onChange={(e: any) =>
                      setState({
                        ...state,
                        fob_ethnicity: e,
                      })
                    }
                  />
                </div>
                <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                  <Text
                    className="font-normal text-blue_gray_900 w-full"
                    as="h5"
                    variant="h5"
                  >
                    Preferred Pronoun
                  </Text>
                  <SelectBox
                    className="font-normal leading-[normal] text-blue_gray_900 text-left text-sm w-full"
                    placeholderClassName="text-blue_gray_900"
                    indicator={
                      <Img
                        src="images/img_close_blue_a400.svg"
                        className="h-2 w-2"
                        alt="close"
                      />
                    }
                    size="sm"
                    isSearchable={false}
                    placeholder=""
                    shape="RoundedBorder6"
                    isMulti={false}
                    options={preferredPronounOptions}
                    name="inputfield_Five"
                    variant="OutlineBluegray200"
                    value={
                      preferredPronounOptions?.filter(
                        (item: any) =>
                          item.value === state?.fob_preferredpronoun
                      )[0]
                    }
                    onChange={(e: any) =>
                      setState({
                        ...state,
                        fob_preferredpronoun: e,
                      })
                    }
                  />
                </div>

                <div className="flex flex-col gap-2.5 items-start justify-start w-full mb-2">
                  <Text
                    className="font-normal text-blue_gray_900 w-full"
                    as="h5"
                    variant="h5"
                  >
                    Nationality
                  </Text>
                  <Input
                    wrapClassName="w-full"
                    className="font-normal leading-[normal] p-0 placeholder:text-blue_gray_900 text-blue_gray_900 text-left text-sm w-full"
                    name="inputfield_Nine"
                    placeholder=""
                    shape="RoundedBorder6"
                    size="sm"
                    variant="OutlineBluegray200"
                    value={state?.fermsapp_nationality}
                    onChange={(e: any) =>
                      setState({ ...state, fermsapp_nationality: e })
                    }
                  ></Input>
                </div>

                <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                  <Text
                    className="font-normal text-blue_gray_900 w-full"
                    as="h5"
                    variant="h5"
                  >
                    Preferred Contact Method
                  </Text>
                  <SelectBox
                    className="font-normal leading-[normal] text-blue_gray_900 text-left text-sm w-full"
                    placeholderClassName="text-blue_gray_900"
                    indicator={
                      <Img
                        src="images/img_close_blue_a400.svg"
                        className="h-2 w-2"
                        alt="close"
                      />
                    }
                    size="sm"
                    isSearchable={false}
                    placeholder=""
                    shape="RoundedBorder6"
                    isMulti={false}
                    options={inputfieldFiveOptionsList}
                    name="inputfield_Five"
                    variant="OutlineBluegray200"
                    value={
                      inputfieldFiveOptionsList?.filter(
                        (item: any) =>
                          item.value === state?.preferredcontactmethodcode
                      )[0]
                    }
                    onChange={(e: any) =>
                      setState({
                        ...state,
                        preferredcontactmethodcode: e,
                      })
                    }
                  />
                </div>

                <Img
                  src="images/img_input.svg"
                  className="h-px w-full"
                  alt="input"
                />
                <div className="flex flex-col gap-2.5 items-start justify-start w-full update-fixed">
                  <Button
                    className="common-pointer cursor-pointer font-semibold leading-[normal] text-base text-center text-white_A700 remove_focus"
                    onClick={handleSubmit}
                    shape="RoundedBorder8"
                    size="md"
                    variant="FillBlueA400"
                  >
                    {loading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="loading-spinner" />
                        <span
                          className="button-text"
                          style={{
                            marginLeft: "2px",
                          }}
                        >
                          Updating...
                        </span>
                      </div>
                    ) : (
                      "Update Contact"
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateContactPage;
